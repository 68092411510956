import request from '@/utils/request'

export function addInit() {
    return request({
      url: "/api/rakuten/addInit",
      method: "get"
    })
}

// 查询分页
export function listPage(params) {
    return request({
      url: "/api/rakuten/listPage",
      params,
      method: "get"
    }) 
  }
  
// 查询初始化
export function PageInit() {
    return request({
      url: "/api/rakuten/pageInit",
      method: "get"
    })
}

export function detail(id) {
  return request({
      url:`/api/rakuten/${id}`,
      method:'get'
  });
}
//同步订单初始化
export function ordersyncInit() {
  return request({
      method:'get',
      url:'/api/rakuten/ordersyncInit',
  });
}
//同步订单确认
export function ordersync(params) {
  return request({
    url: "/api/rakuten/ordersync",
    params,
    method: "get"
  }) 
}
//备注
export function setOrderSysTips(data) {
  return request({
    url: "/api/rakuten/setOrderSysTips",
    data,
    method: "post"
  })
}
//编辑系统备注初始化
export function systemMemoInit(id) {
  return request({
      method:'get',
      url:`/api/rakuten/systemMemoInit/${id}`,
  });
}